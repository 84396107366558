import {
  OrderPricing,
  SubscriptionType,
} from '@components/User/Dashboard/Subscriptions/utils/DataTypes'
import { getSubAddOns } from './subscriptionAddOn'
import api from '@services/api'
import _ from 'lodash'
import { loadSubscriptionData } from '@components/User/Dashboard/Subscriptions/utils/loadSubcriptionData'
import { contents } from '@utils/ProductData'
import { getPackagingVolume } from './packaging'
import { bundles } from '@components/AddOns/AddOnsData'
import { hamIds } from '@components/AddOns/AddOnsData'

export const getLTV = async (subscription: SubscriptionType) => {
  try {
    const { data } = await api.getSubscriptionLTV(
      subscription?.nextOrder?.customer,
      subscription?.details?.id
    )
    const ltv = {
      subscriptionId: subscription?.details?.id,
      totalSaved: data?.total_saved,
      totalSpent: data?.total_spent,
      count: data?.count,
      orders: data?.orders,
    }
    return ltv
  } catch (error) {
    console.error(error)
  }
}

export const fetchSubscriptions = async (
  bcCustomerId: string,
  dispatchCallBack: Function
): Promise<SubscriptionType[]> => {
  try {
    const getSubs = async () => {
      const { data } = await api.getSubscriptions(bcCustomerId)
      return data
    }

    return await getSubs().then((data): SubscriptionType[] => {
      if (!!data?.active_subscriptions) {
        const newSubscriptions: Array<SubscriptionType> = data.active_subscriptions
          .slice()
          .map((subscription) => {
            let loadedSubscription = loadSubscriptionData(subscription)
            const addOns = getSubAddOns(loadedSubscription)
            loadedSubscription.nextOrderAddOns = addOns
            return loadedSubscription
          })
          .sort((a, b) => {
            try {
              const productAOrderDate = new Date(a.nextOrder.place)
              const productBOrderDate = new Date(b.nextOrder.place)

              if (productAOrderDate < productBOrderDate) {
                return -1
              }
              if (productAOrderDate > productBOrderDate) {
                return 1
              }

              if (productAOrderDate === productBOrderDate) {
                const productASubId = a.details.id
                const productBSubId = b.details.id

                if (productASubId < productBSubId) {
                  return -1
                }

                if (productASubId > productBSubId) {
                  return 1
                }

                return 0
              }

              return 0
            } catch (error) {
              console.error(error)

              // HANDLE ERROR SORTING BY DATE
              const productASubId = a.details.id
              const productBSubId = b.details.id

              if (productASubId < productBSubId) {
                return -1
              }

              if (productASubId > productBSubId) {
                return 1
              }

              return 0
            }
          })

        newSubscriptions?.forEach(async (subscription) => {
          await getActiveSubMetaData(subscription)
        })

        dispatchCallBack({
          type: 'SET_ACTIVE_SUBSCRIPTIONS',
          payload: newSubscriptions,
        })

        return newSubscriptions
      }
    })
  } catch (error) {
    console.error(error)
  }
}

export const getActiveSubsMetaData = (
  subscriptions: Array<SubscriptionType>
) => {
  subscriptions?.forEach(
    async (subscription) => await getActiveSubMetaData(subscription)
  )
  return
}

export const getActiveSubMetaData = async (subscription: SubscriptionType) => {
  const maxAddOns = getMaxAddOns(subscription)
  subscription.nextOrder.maxAddOns = maxAddOns

  const remainingVolume = getSubNextOrderRemainingVolume(subscription)
  subscription.nextOrder.remainingVolume = remainingVolume

  const pricing: OrderPricing = calculatePrices(subscription)
  subscription.nextOrder.orderPrice = pricing

  const totalAddOnsVolume = getTotalAddOnsVolume(subscription)
  subscription.nextOrder.totalAddOnsVolume = totalAddOnsVolume

  subscription.nextOrder.canAddAddOns = maxAddOns > totalAddOnsVolume

  const ltv = await getLTV(subscription)
  subscription.ltv = ltv

  return
}

const offerItems = [
  {
    name: 'Standard offer',
    id: '8c02591a6fd211ec9d833e4fde43efe4',
    addedItems: 0,
  },
  {
    name: '2lbs Chicken GWP',
    id: '42225d067cb811eda3b576308bb688bc',
    addedItems: 1,
  },
  {
    name: '2 pack Bacon GWP',
    id: '5440342aa97e11ed8dac2633135a3d5b',
    addedItems: 1,
  },
  {
    name: '2lbs Ground Beef GWP',
    id: '2b373d78321611eeb38e163ff997b255',
    addedItems: 1,
  },
]

const getMaxAddOns = (subscription: SubscriptionType) => {
  const subQuantity = subscription?.nextOrder?.subscription_item?.quantity

  let volumeOfContents = 0
  const subSKU = subscription?.product?.sku

  Object.entries(contents).map(([key, value], index) => {
    value.sku == subSKU ? (volumeOfContents = value.maxAddOns) : null
  })

  // Get GWP Items
  let gwpCount = 0
  const subOffer = subscription?.details?.offer

  for (let offer of offerItems) {
    if (offer.id === subOffer) {
      gwpCount += offer.addedItems
    }
  }

  // Set MaxAddOns
  const maxAddOnVolume = (volumeOfContents - gwpCount) * subQuantity
  return maxAddOnVolume
}

export const calculatePrices = (subscription: SubscriptionType) => {
  const shippingTotal = 0
  const taxTotal = 0
  const subItemMSRP = parseFloat(subscription?.product?.price)
  const customerSubItemPrice = parseFloat(
    subscription?.nextOrder?.subscription_item?.price
  )
  const { quantity } = subscription?.nextOrder?.subscription_item

  let _total = 0
  let _totalSavings = 0

  subscription?.items?.map((item) => {
    _total += parseFloat(item?.total_price)
    _totalSavings +=
      parseFloat(item?.price) * item?.quantity - parseFloat(item?.total_price)
  })

  const subTotal = subItemMSRP * quantity
  const priceChangeOffset = (subItemMSRP - customerSubItemPrice) * quantity
  const subscriptionDiscountsValue = 25 * quantity

  // Aggregate discounts and match to items
  const oneTimeDiscounts = subscription?.oneTimeIncentives
  if (oneTimeDiscounts?.length > 0) {
    oneTimeDiscounts?.map((discount) => {
      const discountValue = discount?.incentive?.value
      const stackingType = discount?.stacking_type
      const discountId = discount?.public_id
      const discountItem = discount?.item
      const discountOrder = discount?.order
      const discountTarget = discount?.incentive?.target
      const discountType = discount?.incentive?.discount_type

      if (discountTarget === 'item') {
        if (!!discountItem && discountItem != '') {
          subscription?.items?.map((item) => {
            if (item?.public_id === discountItem) {
              item.discounts.push({
                stackingType: stackingType,
                type: discountType,
                value: parseFloat(discountValue),
                id: discountId,
              })
            }
          })
        } else if (!!discountOrder && discountOrder != '') {
          subscription?.items?.map((item) => {
            item.discounts.push({
              stackingType: stackingType,
              type: discountType,
              value: parseFloat(discountValue),
              id: discountId,
            })
          })
        }
      }
    })
  }

  subscription?.items?.map((item) => {
    if (item?.product === subscription?.product?.productId) {
      item.discounts.push({
        stackingType: 'base',
        type: 'Discount Amount',
        value: 25,
        id: 'base_subscription_discount',
      })
    }
  })

  // Calculate total discounts on a per item basis
  let totalDiscounts = 0
  subscription?.items?.map((item) => {
    const stackingDiscounts = item.discounts?.filter(
      (item) => item.stackingType === 'additional'
    )
    const baseDiscounts = item.discounts?.filter(
      (item) => item.stackingType === 'base'
    )

    let stackingDiscountsTotal = 0
    stackingDiscounts.forEach((discount) => {
      const discountAmount =
        discount?.type === 'Discount Amount'
          ? discount?.value
          : discount?.value * 0.01 * parseFloat(item?.price)

      stackingDiscountsTotal += discountAmount * item.quantity
    })

    let largestBaseDiscount = 0
    baseDiscounts.forEach((discount) => {
      const discountAmount =
        discount?.type === 'Discount Amount'
          ? discount?.value
          : discount?.value * 0.01 * parseFloat(item?.price)
      if (discountAmount > largestBaseDiscount) {
        largestBaseDiscount = discountAmount * item.quantity
      }
    })

    const totalItemDiscounts = stackingDiscountsTotal + largestBaseDiscount
    const itemTotal = parseFloat(item?.price) * item?.quantity

    totalDiscounts +=
      totalItemDiscounts > itemTotal ? itemTotal : totalItemDiscounts
  })

  let addOnsTotal = 0

  for (const addOn of subscription?.nextOrderAddOns) {
    for (const item of addOn?.items) {
      addOnsTotal += parseFloat(item.price) * item.quantity
    }
  }

  const subTotalWithAddOns = addOnsTotal + subTotal
  const totalNextOrderSavings = totalDiscounts - subscriptionDiscountsValue
  let totalAfterAllDiscounts =
    subTotalWithAddOns - totalDiscounts - priceChangeOffset

  if (totalAfterAllDiscounts < 0) {
    totalAfterAllDiscounts = 0
  }

  const pricing: OrderPricing = {
    subTotal: String(_total.toFixed(2)),
    shippingTotal: String(shippingTotal.toFixed(2)),
    taxTotal: String(taxTotal.toFixed(2)),
    lockedInPriceSavings: String(priceChangeOffset.toFixed(2)),
    subscriptionSavings: String(subscriptionDiscountsValue.toFixed(2)),
    nextOrderSavings: String(totalNextOrderSavings.toFixed(2)),
    total: String(_total.toFixed(2)),
    addOnsTotal: String(addOnsTotal.toFixed(2)),
    totalSavings: String(_totalSavings.toFixed(2)),
  }

  return pricing
}

const getTotalAddOnsVolume = (subscription: SubscriptionType) => {
  const nextOrderAddOns = subscription?.nextOrderAddOns
  let totalAddOnsVolume = 0

  if (nextOrderAddOns?.length > 0) {
    totalAddOnsVolume = nextOrderAddOns.reduce((acc, addOn) => {
      return acc + addOn.volume * addOn.quantity
    }, 0)
  }

  return totalAddOnsVolume
}

const getOrderHasHam = (subscription: SubscriptionType) => {
  const nextOrderAddOns = subscription?.nextOrderAddOns
  let hasHam = false

  if (nextOrderAddOns?.length > 0) {
    nextOrderAddOns.map((addOn) => {
      if (hamIds.includes(addOn.id)) {
        hasHam = true
      }
    })
  }

  return hasHam
}

const getOrderHasHadHam = (subscription: SubscriptionType) => {
  const extraData = subscription?.nextOrder?.extra_data

  const hasHadHam = extraData?.has_had_ham?.some((subId) => {
    subId === subscription?.details?.id
  })
  return hasHadHam
}

const getContentsVolume = (subscription: SubscriptionType) => {
  let volumeOfBox = 0
  const subSKU = subscription?.product?.sku
  Object.entries(contents).map(([key, value], index) => {
    const skuIsList = _.isArray(value.sku)
    if (skuIsList) {
      value.sku.includes(subSKU) ? (volumeOfBox = value.contentsVolume) : null
    } else {
      value.sku == subSKU ? (volumeOfBox = value.contentsVolume) : null
    }
  })
  return volumeOfBox * subscription?.nextOrder?.subscription_item?.quantity
}

const getSubNextOrderRemainingVolume = (subscription: SubscriptionType) => {
  const totalAddOnsVolume = getTotalAddOnsVolume(subscription)
  const boxContentsVolume = getContentsVolume(subscription)
  let nextSubOrderTotalContentsVolume = boxContentsVolume + totalAddOnsVolume
  const orderHasHadHam = getOrderHasHadHam(subscription)
  const orderHasHam = getOrderHasHam(subscription)

  if (!orderHasHam && orderHasHadHam) {
    nextSubOrderTotalContentsVolume +=
      bundles['Spiral-Cut Heritage Ham (OTP)']['volume']
  }

  const packagingVolume = getPackagingVolume(nextSubOrderTotalContentsVolume)

  let remainingVolume = packagingVolume - nextSubOrderTotalContentsVolume

  if (!orderHasHam && orderHasHadHam) {
    remainingVolume += bundles['Spiral-Cut Heritage Ham (OTP)']['volume']
  }

  return remainingVolume
}
