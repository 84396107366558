const getCardType = (number) => {
  const types = [
    'Visa',
    'Mastercard',
    'American Express',
    'Discover',
    'Diners',
    'JCB',
  ]
  if (number > types.length || number < 1) return null
  else {
    return types[number - 1]
  }
}

export default getCardType
