import { bundles } from '@components/AddOns/AddOnsData'
import {
  SubscriptionType,
  SubOrderAddOn,
} from '@components/User/Dashboard/Subscriptions/utils/DataTypes'
import api from '@services/api'
import _ from 'lodash'
import { nextOrderAddOnType } from 'types/nextOrderAddOns'
import { DeleteSubOrderAddOnResponse } from 'types/subscriptions'

const standardAddonOffer = '2590568e320711eeadd49a40a6accdb3'
const freeAddonOffer = '6ddb2e9e874a11eebc7f8269ff2f5a05'
const maxUniqueOrderAddOns = 5

const getOfferProfile = ({
  free,
  bundleItemOffer,
}: {
  free: boolean
  bundleItemOffer: string | null
}): string => {
  let offerProfile = !free ? standardAddonOffer : freeAddonOffer
  if (!free && !_.isUndefined(bundleItemOffer) && !_.isNull(bundleItemOffer)) {
    offerProfile = bundleItemOffer
  }
  return offerProfile
}

const editSubOrderItem = async (itemId: string, quantity: number) => {
  try {
    const req = await api.editNextOrderItem(itemId, quantity)

    if (req?.status !== 200) {
      throw new Error(
        'Error editing next order item, API Request is ' + req?.status
      )
    }
    return req
  } catch (error) {
    console.log(error)
    return { message: `error - ${error}` }
  }
}

const deleteSubOrderItem = async (itemId: string): Promise<void> => {
  try {
    const req = await api.deleteNextOrderItem(itemId)
    if (req?.status !== 200) {
      throw new Error(
        'Error deleting next order item, API Request is ' + req?.status
      )
    }
  } catch (error) {
    console.log(error)
  }
}

const deleteSubOrderAddOn = async (addOn: SubOrderAddOn): Promise<DeleteSubOrderAddOnResponse> => {
  try {
    const itemsToDelete = addOn?.items?.slice()
    for (const item of itemsToDelete) {
      await deleteSubOrderItem(item.public_id)
    }
    return { message: 'success' }
  } catch (error) {
    console.log(error)
    return { message: 'error deleting items for add on' }
  }
}

export const addSubOrderAddOn = async (
  orderId: string,
  addOnId: string,
  addOn: SubOrderAddOn | undefined,
  addOnQuantity: number,
  subscription: SubscriptionType,
  free: boolean
): Promise<void> => {
  try {
    const nextOrder = subscription?.nextOrder
    const subId = subscription?.details?.id

    // ******* GET EXTRA ITEM DATA ********* //
    const extraData = nextOrder?.extra_data || { add_ons: [] }
    const addOns = extraData?.add_ons || []
    const publicIds = []
    if (!_.isEmpty(addOns)) {
      const addOnItems = addOns?.map((addOnBundle) => {
        return addOnBundle?.public_ids
      })
      addOnItems?.forEach((items) => {
        items?.forEach((item) => {
          publicIds.push(item)
        })
      })
    }

    // ******* UPDATE NEXT ORDER ADD ONS ********* //
    const _reqPubIds = []
    if (!_.isEmpty(addOn)) {
      for (const addOnItem of addOn.items) {
        let addOnFound = false
        for (const publicId of publicIds) {
          if (publicId === addOnItem?.public_id) {
            addOnFound = true
          }
        }

        const bundleItems = bundles[addOnId]['items']
        const bundleItem = bundleItems.find(
          (item) => addOnItem?.product === item.external_id
        )

        _reqPubIds.push(addOnItem?.public_id)

        let req
        if (addOnFound) {
          req = await editSubOrderItem(
            addOnItem.public_id,
            bundleItem.quantity * addOnQuantity
          )
        }

        if (req?.status !== 200) {
          throw new Error(
            'Error creating next order add on, API Request is ' + req?.status
          )
        }
      }
    } else {
      const bundleItems = bundles[addOnId]['items']
      let req
      for (const bundleItem of bundleItems) {
        const data: nextOrderAddOnType = {
          order: orderId,
          offer: getOfferProfile({ free, bundleItemOffer: bundleItem?.offer }),
          product: bundleItem?.external_id,
          quantity: bundleItem?.quantity * addOnQuantity,
        }

        req = await api.createNextOrderItem(data)

        if (req?.status !== 200) {
          throw new Error(
            'Error creating next order add on, API Request is ' + req?.status
          )
        }
        _reqPubIds.push(req?.data?.data?.public_id)
      }
    }

    // ******* UPDATE EXTRA ITEM DATA ********* //
    const otherSubAddOns = addOns.filter(
      (obj) => obj?.subscription_id !== subId
    )
    const thisSubAddOns = addOns.filter((obj) => obj?.subscription_id === subId)
    const thisSubAddOnsFiltered = thisSubAddOns.filter(
      (obj) =>
        obj?.id !== addOnId ||
        (obj?.id === addOnId && (_.isUndefined(obj?.free) || obj.free !== free))
    )

    thisSubAddOnsFiltered.push({
      id: addOnId,
      quantity: addOnQuantity,
      public_ids: _reqPubIds,
      subscription_id: subId,
      free: free,
    })

    const newSubAddOns = otherSubAddOns.concat(thisSubAddOnsFiltered)
    const newExtraData = { ...extraData, add_ons: newSubAddOns }
    await api.editOrderExtraData(orderId, newExtraData)
  } catch (error) {
    console.log(error)
  }
}

export const removeSubOrderAddOn = async (
  orderId: string,
  addOnId: string,
  addOn: SubOrderAddOn,
  addOnQuantity: number,
  subscription: SubscriptionType,
  free: boolean
): Promise<void> => {
  try {
    const nextOrder = subscription?.nextOrder
    const subId = subscription?.details?.id

    // ******* GET EXTRA ITEM DATA ********* //
    const extraData = nextOrder?.extra_data || { add_ons: [] }
    const addOns = extraData?.add_ons || []
    const publicIds = []
    if (!_.isEmpty(addOns)) {
      const addOnItems = addOns?.map((item) => {
        return item?.public_ids
      })
      addOnItems?.forEach((items) => {
        items?.forEach((item) => {
          publicIds.push(item)
        })
      })
    }

    // ******* UPDATE NEXT ORDER ADD ONS ********* //
    const _publicIds = []
    for (const addOnItem of addOn.items) {
      let addOnFound = false
      for (const publicId of publicIds) {
        if (publicId === addOnItem?.public_id) {
          addOnFound = true
        }
      }

      const bundleItems = bundles[addOnId]['items']
      const bundleItem = bundleItems.find(
        (item) => addOnItem?.product === item.external_id
      )

      let req
      let deleted = false
      if (addOnFound) {
        if (addOnQuantity === 0) {
          req = await deleteSubOrderAddOn(addOn)
          deleted = true
        } else {
          req = await editSubOrderItem(
            addOnItem.public_id,
            bundleItem.quantity * addOnQuantity
          )
          _publicIds.push(addOnItem?.public_id)
        }
      }

      if (req?.status !== 200 && req?.message !== 'success') {
        throw new Error(
          'Error creating next order add on, API Request is ' + req?.status
        )
      }

      if (publicIds?.length <= 0) {
        publicIds.push(req?.data?.data?.public_id)
      }

      if (deleted) {
        break
      }
    }

    // ******* UPDATE EXTRA ITEM DATA ********* //
    const otherSubAddOns = addOns.filter(
      (obj) => obj?.subscription_id !== subId
    )
    const thisSubAddOns = addOns.filter((obj) => obj?.subscription_id === subId)

    const thisSubAddOnsFiltered = thisSubAddOns.filter(
      (obj) =>
        obj?.id !== addOnId ||
        (obj?.id === addOnId && (_.isUndefined(obj?.free) || obj.free !== free))
    )

    if (addOnQuantity > 0) {
      thisSubAddOnsFiltered.push({
        id: addOnId,
        quantity: addOnQuantity,
        public_ids: _publicIds,
        subscription_id: subId,
        free: free,
      })
    }
    const newSubAddOns = otherSubAddOns.concat(thisSubAddOnsFiltered)
    const newExtraData = { ...extraData, add_ons: newSubAddOns }
    await api.editOrderExtraData(orderId, newExtraData)
  } catch (error) {
    console.log(error)
  }
}

export const getSubAddOns = (
  subscription: SubscriptionType
): SubOrderAddOn[] => {
  const subAddOns: SubOrderAddOn[] = []
  const subExtraData = subscription?.nextOrder?.extra_data
  if (
    !_.isEmpty(subscription?.items) &&
    !!subExtraData?.add_ons &&
    !_.isEmpty(subExtraData?.add_ons) &&
    subExtraData?.add_ons?.length > 0
  ) {
    const _addons = subExtraData?.add_ons

    _addons.map((_addon) => {
      const _bundle = bundles[_addon.id]

      const _addonItems = []
      _addon?.public_ids?.map((id) => {
        const _item = subscription?.items?.find(
          (item) => item?.public_id === id
        )
        if (!_.isEmpty(_item)) {
          _addonItems.push(_item)
        }
      })
      if (_addonItems.length !== _addon?.public_ids?.length) return
      const _subaddon = {
        id: _addon.id,
        quantity: _addon.quantity,
        items: _addonItems,
        volume: _bundle['volume'],
        subscriptionId: subscription?.details?.id,
        free: _addon?.free || false,
      }
      subAddOns.push(_subaddon)
    })
  }
  subscription.nextOrderUniqueAddOnsMaxed =
    subExtraData?.add_ons?.length >= maxUniqueOrderAddOns

 
  return subAddOns || []
}


