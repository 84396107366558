export const containers = {
  single: {
    capacity: 7,
  },
  double: {
    capacity: 12,
  },
}

export const getPackagingVolume = (contentsVolume: number) => {
  let numDoubleBoxes = 0
  let numSingleBoxes = 0

  numDoubleBoxes = Math.floor(contentsVolume / containers.double.capacity)

  const remainder = contentsVolume % containers.double.capacity

  if (remainder > containers.single.capacity) {
    numDoubleBoxes++
    return numDoubleBoxes * containers.double.capacity
  }

  if (remainder === 0) {
    return numDoubleBoxes * containers.double.capacity
  }

  if (remainder <= containers.single.capacity) {
    numSingleBoxes++
    return (
      numDoubleBoxes * containers.double.capacity +
      numSingleBoxes * containers.single.capacity
    )
  }
}
