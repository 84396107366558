import getCardType from './CardTypes'
import { SubscriptionType } from './DataTypes'

export const loadSubscriptionData = (subscription) => {
  const newSubscription: SubscriptionType = {
    nextOrder: subscription?.subscription?.live
      ? {
          merchant: subscription?.next_order?.results[0]?.merchant,
          customer: subscription?.next_order?.results[0]?.customer,
          payment: subscription?.next_order?.results[0]?.payment,
          shipping_address:
            subscription?.next_order?.results[0]?.shipping_address,
          public_id: subscription?.next_order?.results[0]?.public_id,
          sub_total: subscription?.next_order?.results[0]?.sub_total,
          tax_total: subscription?.next_order?.results[0]?.tax_total,
          shipping_total: subscription?.next_order?.results[0]?.shipping_total,
          discount_total: subscription?.next_order?.results[0]?.discount_total,
          total: subscription?.next_order?.results[0]?.total,
          created: subscription?.next_order?.results[0]?.created,
          place: subscription?.next_order?.results[0]?.place,
          place_string: subscription?.next_order?.results[0]?.place_string,
          canceled: subscription?.next_order?.results[0]?.canceled,
          tries: subscription?.next_order?.results[0]?.tries,
          generic_error_count:
            subscription?.next_order?.results[0]?.generic_error_count,
          status: subscription?.next_order?.results[0]?.status,
          type: subscription?.next_order?.results[0]?.type,
          order_merchant_id:
            subscription?.next_order?.results[0]?.order_merchant_id,
          rejected_message:
            subscription?.next_order?.results[0]?.rejected_message,
          extra_data: JSON.parse(
            subscription?.next_order?.results[0]?.extra_data
          ),
          locked: subscription?.next_order?.results[0]?.locked,
          oos_free_shipping:
            subscription?.next_order?.results[0]?.oos_free_shipping,
          subscription_item:
            subscription?.next_order?.results[0]?.subscription_item,
        }
      : null,
    details: {
      id: subscription?.subscription?.public_id,
      startDate: subscription?.subscription?.start_date.slice(0, 10),
      nextOrderDate: subscription?.subscription?.live
        ? subscription?.next_order?.results[0]?.place
        : null,
      lastUpdated: subscription?.subscription?.updated?.slice(0, 10),
      frequency: subscription?.subscription?.every,
      period: subscription?.subscription?.every_period,
      quantity:
        subscription?.next_order?.results[0]?.subscription_item?.quantity,
      status: subscription?.subscription?.live,
      cancelled: subscription?.subscription?.cancelled,
      offer: subscription?.subscription?.offer,
    },
    product: {
      name: subscription?.product?.name,
      sku: subscription?.product?.sku,
      price: subscription?.product?.price,
      imageUrl: subscription?.product?.image_url,
      detailUrl: subscription?.product?.detail_url,
      productId: subscription?.product?.external_product_id,
    },
    shipping: {
      firstName: subscription?.shipping?.first_name,
      lastName: subscription?.shipping?.last_name,
      streetAddress: subscription?.shipping?.address,
      addressLine2: subscription?.shipping?.address2,
      city: subscription?.shipping?.city,
      zip: subscription?.shipping?.zip_postal_code,
      state: subscription?.shipping?.state_province_code,
    },
    billing: {
      card: {
        ending: subscription?.payment?.cc_number_ending,
        type: getCardType(subscription?.payment?.cc_type),
        expiration: subscription?.payment?.cc_exp_date,
      },
    },
    billingState: subscription?.billing_address?.state_province_code,
    oneTimeIncentives: subscription?.subscription?.live
      ? subscription?.one_time_incentives
      : [],
    items: subscription?.items?.results,
  }

  newSubscription.items.forEach((item) => {
    item.discounts = []
  })

  return newSubscription
}
